<template>
  <div class="day-pieteikumi" style="max-width: 420px; margin: 0 auto">
    <b-modal
      id="modal-xl"
      scrollable
      :title="
        'Nodarbību vēsture kursantam ' + historyName + ' ' + historySurname
      "
    >
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Datums</th>
            <th scope="col">Laiks</th>
            <th scope="col">Stundas</th>
            <th scope="col">Auto RN</th>
            <th scope="col">Tēma</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(lesson, index) in history" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ formatDate(lesson.datums) }}</td>
            <td>{{ formatTime(lesson.datums) }}</td>
            <td>{{ lesson.br_stundas }}</td>
            <td>{{ lesson.rn }}</td>
            <td>{{ lesson.kbnt_kods ? lesson.kbnt_kods : '-' }}</td>
          </tr>
        </tbody>
      </table>
      <!--<div class="row" v-for="(lesson, index) in history" :key="index">
        <div class="col-12">
          <hr />
          <b>{{ index + 1 }}</b>
          <br />
          Pieteikuma laiks:
          <b>{{
            formatTime(lesson.datums) + " " + formatDate(lesson.datums)
          }}</b>
          <br />
          Mācību stundu skaits: {{ lesson.br_stundas }}
          <br />
          Auto reģ. nr. - {{ lesson.rn }}
          <br />
          Tēma - {{ lesson.kbnt_kods }}
        </div>
      </div>-->
      <template #modal-footer="{ ok }">
        <b-button class="submit-button" @click="ok()">Atpakaļ</b-button>
      </template>
    </b-modal>
    <div
      class="saraksts"
      v-if="pieteikumi !== undefined && pieteikumi !== null"
    >
      <div style="margin-bottom: 25px">
        <b-form-input
          type="text"
          id="searchUzvards"
          v-model="searchUzvards"
          placeholder="Meklēt pēc uzvārda"
        />
      </div>

      <div
        class="text-center"
        v-if="filteredPieteikumiName.length == 0"
      >
        <h2>Nekas netika atrasts</h2>
      </div>

      <div
        v-for="pieteikums in filteredPieteikumiName"
        :key="pieteikums.id"
        class="my-1"
      >
        <b-card
          no-body
          class="card"
          :style="{ minHeight: $mq == 'sm' || $mq == 'xs' ? '100px' : '250px' }"
        >
          <b-card-text>
            <div class="row">
              <b-button
                @click="addNewClicked(pieteikums)"
                style="background: #365140; margin-left: 4%"
                >+</b-button
              >
              <b-button
                @click="historyClicked(pieteikums)"
                style="background: #365140; margin-left: 4%"
                ><i class="fa fa-history" aria-hidden="true"></i>
              </b-button>
              <h3 style="margin-left: 10px">
                {{ pieteikums.vards + " " + pieteikums.uzvards }}
              </h3>
            </div>
          </b-card-text>
          <b-card-text>
            {{ pieteikums.gjps_inos }}
          </b-card-text>
          <b-card-text
            >Personas kods:
            <span style="white-space: nowrap"
              >{{ pieteikums.pk
              }}<span style="color: lightgray"> - * * * * *</span></span
            >
          </b-card-text>
          <b-card-text
            >Pieteikuma laiks:
            <b>{{
              formatTime(pieteikums.datums) +
              " " +
              formatDate(pieteikums.datums)
            }}</b></b-card-text
          >
          <b-card-text v-if="pieteikums.kbnt_nos">
            Nodarbības tēma: {{ pieteikums.kbnt_nos }}
          </b-card-text>
          <b-card-text>
            <b-button
              v-if="pieteikums.ier == false && isToday(pieteikums.datums)"
              class="submit-button"
              @click="ieskaititQuestion(pieteikums)"
              >Ieradies</b-button
            >
            <b-button
              v-if="pieteikums.ier == false || isToday(pieteikums.datums)"
              class="delete-button"
              @click="deleteQuestion(pieteikums)"
              >Dzēst</b-button
            >
          </b-card-text>
          <b-button
            v-if="pieteikums.var_abap == true"
            class="apliecinajuma-button"
            @click="addApliecinajumsQuestion(pieteikums)"
            >Pievienot apliecinājumu par braukšanas apmācību
            pabeigšanu</b-button
          >
          <b-button
            v-if="pieteikums.abap == true && pieteikums.var_abap == false"
            class="apliecinajuma-delete-button"
            @click="removeApliecinajumsQuestion(pieteikums)"
            >Noņemt apliecinājumu par braukšanas apmācību pabeigšanu</b-button
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import toast from "../services/toast"
import "izitoast/dist/css/iziToast.min.css"
import iZiToast from "izitoast"

export default {
  name: "DayPieteikumi",
  props: ["reload"],
  data() {
    return {
      pieteikumi: null,
      searchUzvards: "",
      history: null,
      historyName: null,
      historySurname: null
    }
  },
  methods: {
    addNewClicked(pieteikums) {
      this.$emit("new-clicked", pieteikums.ukur_id)
    },
    historyClicked(pieteikums) {
      this.historyName = pieteikums.vards
      this.historySurname = pieteikums.uzvards

      axios
        .post("api/qry-ukur-ubs-v", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: pieteikums.ukur_id
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            this.error = response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage
          } else {
            this.history = response.data.rowset
          }
        })
        .catch((error) => console.log(error))

      this.$bvModal.show("modal-xl")
    },
    isToday(someDate) {
      someDate = new Date(someDate)
      const today = new Date()

      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      )
    },
    formatDate(pDate) {
      pDate = pDate.split("T")[0]

      let yyyy = pDate.split("-")[0].padStart(2, "0")
      let mm = pDate.split("-")[1].padStart(2, "0")
      let dd = pDate.split("-")[2].split(" ")[0]

      return dd + "." + mm + "." + yyyy
    },
    formatTime(pDate) {
      pDate = pDate.split("T")[1]

      return pDate.slice(0, 5)
    },
    formatDateTime() {
      this.date.setHours(this.time.HH, this.time.mm, 0)

      return new Date(
        this.date.getTime() - this.date.getTimezoneOffset() * 60000
      )
        .toJSON()
        .toString()
        .slice(0, 19)
    },
    addApliecinajumsQuestion(item) {
      var vue = this

      iZiToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: "once",
        id: "question",
        zindex: 999,
        title: "",
        message:
          "Vai vēlaties apliecināt ka braukšanas apmācība pabeigta un persona drīkst kārtot vadīšanas eksāmenu autoskolā?",
        position: "center",
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
              vue.addApstiprinajums(item);
            },
          ],
          [
            "<button>Nē</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
            }
          ]
        ]
      })
    },
    addApstiprinajums(pieteikums) {
      axios
        .post("api/ins-ubap", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: pieteikums.ukur_id,
          dzest: false
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage)
          } else {
            toast.success("Uzdotā darbība izpildīta veiksmīgi")
            this.loadPieteikumi()
          }
        })
        .catch((error) => console.log(error))
    },
    removeApliecinajumsQuestion(item) {
      var vue = this;
      iZiToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: "once",
        id: "question",
        zindex: 999,
        title: "",
        message:
          "Vai vēlaties atcelt apliecinājumu ka braukšanas apmācība pabeigta un persona drīkst kārtot vadīšanas eksāmenu autoskolā?",
        position: "center",
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
              vue.addApstiprinajums(item);
            },
          ],
          [
            "<button>Nē</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
            },
          ]
        ]
      })
    },
    removeApstiprinajums(pieteikums) {
      axios
        .post("api/ins-ubap", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: pieteikums.ukur_id,
          dzest: true
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage)
          } else {
            toast.success("Uzdotā darbība izpildīta veiksmīgi")
            this.loadPieteikumi()
          }
        })
        .catch((error) => console.log(error))
    },
    ieskaititQuestion(item) {
      var vue = this

      iZiToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: "once",
        id: "question",
        zindex: 999,
        title: "",
        message: "Vai vēlaties atzīmēt nodarbības apmeklējumu?",
        position: "center",
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
              vue.onIeskaititClick(item);
            },
          ],
          [
            "<button>Nē</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
            },
          ]
        ]
      })
    },
    onIeskaititClick(pieteikums) {
      axios
        .post("api/upd-uis-ubs-atz", {
          sessionId: sessionStorage.getItem("sessionId"),
          ubs_id: pieteikums.ubs_id
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage)
          } else {
            toast.success("Uzdotā darbība izpildīta veiksmīgi")
            this.loadPieteikumi()
          }
        })
        .catch((error) => console.log(error))
    },
    deleteQuestion(item) {
      var vue = this

      iZiToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: "once",
        id: "question",
        zindex: 999,
        title: "",
        message: "Vai tiešām vēlaties dzēst šo nodarbību?",
        position: "center",
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
              vue.onDeleteClick(item)
            }
          ],
          [
            "<button>Nē</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
            }
          ]
        ]
      })
    },
    onDeleteClick(pieteikums) {
      axios
        .post("api/del-uis-ubs", {
          sessionId: sessionStorage.getItem("sessionId"),
          ubs_id: pieteikums.ubs_id
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage)
          } else {
            toast.success("Uzdotā darbība izpildīta veiksmīgi")

            this.loadPieteikumi()
          }
        })
        .catch((error) => console.log(error))
    },
    loadPieteikumi() {
      axios
        .post("api/qry-uis-ubs", {
          sessionId: sessionStorage.getItem("sessionId")
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage)
          } else {
            this.pieteikumi = response.data.rowset
          }
        })
        .catch((error) => console.log(error))
    },
  },
  computed: {
    filteredPieteikumiName: function () {
      if (this.pieteikumi !== undefined && this.pieteikumi !== null) {
        let result = []

        result = this.pieteikumi.filter((item) => {
          return item.uzvards
            .toLowerCase()
            .includes(this.searchUzvards.toLowerCase())
        })

        return result
      } else return []
    }
  },
  watch: {
    person: function () {
      this.error = null
      this.personInfo = null
    },
    date: function () {
      this.error = null
      this.personInfo = null
    }
  },
  mounted() {
    this.loadPieteikumi()
  }
}
</script>

<style scoped>
.card {
  min-height: 330px;
}

.submit-button {
  width: 85px;
  margin-top: 5px;
}

.delete-button {
  width: 85px;
  margin-top: 5px;
}

.saraksts {
  margin-top: 50px;
}
</style>
