<template>
  <div class="content">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <b-form @submit="onSubmit">
          <b-form-group
            id="password-group"
            label="Jaunā parole"
            label-for="password-input"
            description="Parolei jāsākas ar lielo burtu, nedrīkst saturēt jebkādus Jūsu datus, 9-30 simboli, vismaz viens lielais un viens mazais burts, vismaz viens cipars un #, $ vai _ simbols."
          >
            <b-form-input
              v-model="password"
              id="password-input"
              type="password"
              maxLength="30"
              required
              placeholder="********"
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" class="submit-button">Pabeigt paroles atjaunošanu</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import toast from "../services/toast";

export default {
  name: "PasswordResetContinue",
  props: ["kods"],
  data() {
    return {
      password: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      axios
        .post("api/password-reset-finish", {
          password: this.password,
          kods: this.kods,
        })
        .then((response) => {
          this.data = response.data;

          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage);
          } else {
            toast.success("Parole atjaunota veiksmīgi.");
            window.location.href = "#/";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
h3 {
  margin-top: 50px;
}
</style>
