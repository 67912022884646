<template></template>

<script>
import toast from "../services/toast";

export default {
  name: "Logout",
  mounted() {
    axios
      .post("api/logout", {
        sessionId: this.$store.state.sessionId,
      })
      .then((response) => {
        if (response.data.Errors !== undefined) {
          if (
            response.data.Errors[0].ClientMessageCode !== "F-00004" &&
            response.data.Errors[0].ClientMessageCode !== "F-00013"
          ) {
            toast.error(response.data.Errors[0].ClientMessageCode + ' ' + response.data.Errors[0].ClientMessage);
          }
        }
      })
      .catch((error) => console.log(error));

    sessionStorage.clear();
    this.$store.commit("change");
    window.location.href = "#/";
    //window.location.reload();

    this.$store.commit("logout");
    this.$store.state.fpda = null;
  },
};
</script>