<template>
  <div class="content">
    <h2 class="page-title" style="margin: 0 auto; max-width: 420px;">Dokumentācija</h2>
    <hr />
    <div class="w-100 d-flex justify-content-between" style="margin-left: 10px; padding-right: 5px; margin-top: 15px; margin: 0 auto; max-width: 420px;">
      <b-button class="submit-button" @click="openDocument">
        Atvērt dokumentāciju
      </b-button>
      <b-button class="submit-button" @click="openManevri">
        Speciālie manevri
      </b-button>
    </div>
  </div>
</template>

<script>
import "../assets/BosketTreeView.css";
export default {
  name: "DocumentationView",
  data() {
    return {
      //pdf: require("../assets/Med_csdd_pamaciba.pdf"),
    };
  },
  methods: {
    openDocument() {
      window.open("images/Instruktori_BR_NOD_REGISTRACIJA.pdf");
    },
    openManevri() {
      window.open("images/Specialie_manevri_autoskolam.pdf");
    }
  },
  mounted() {
    //document.getElementById("pdf-doc").click();
  }
};
</script>

<style scoped>
#pdf-doc {
  color: white;
  text-decoration: none;
}
</style>
