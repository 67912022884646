<template>
  <div class="" style="margin: 0 auto; max-width: 420px;">
    <b-alert v-model="showError" variant="danger" dismissible>
      {{
        error
      }}
    </b-alert>
    <b-form @submit="onSubmit">
      <b-form-group
        id="password-group"
        label="Jūsu esošā parole:"
        label-for="password-input"
        description="Ievadiet veco paroli"
      >
        <b-form-input
          id="password-group"
          v-model="password"
          type="password"
          maxLength="30"
          required
          autocomplete="current-password"
          oninvalid="this.setCustomValidity('Lūdzu aizpildiet šo lauku.')"
          onchange="this.setCustomValidity('')"
          placeholder="********"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="new-password-group"
        label="Jaunā parole:"
        label-for="new-password-input"
        description="Parolei jāsākas ar lielo burtu, nedrīkst saturēt jebkādus Jūsu datus, 9-30 simboli, vismaz viens lielais un viens mazais burts, vismaz viens cipars un #, $ vai _ simbols."
      >
        <b-form-input
          id="new-password-group"
          v-model="newPassword"
          type="password"
          maxLength="30"
          required
          autocomplete="new-password"
          oninvalid="this.setCustomValidity('Lūdzu aizpildiet šo lauku.')"
          onchange="this.setCustomValidity('')"
          placeholder="********"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="new-password-again-group"
        label="Jaunā parole vēlreiz:"
        label-for="new-password-again-input"
        description="Ievadiet jauno paroli vēlreiz"
      >
        <b-form-input
          id="new-password-again-group"
          v-model="newPasswordAgain"
          type="password"
          maxLength="30"
          required
          autocomplete="new-password"
          oninvalid="this.setCustomValidity('Lūdzu aizpildiet šo lauku.')"
          onchange="this.setCustomValidity('')"
          placeholder="********"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" class="submit-button">Atjaunot paroli</b-button>
    </b-form>
  </div>
</template>

<script>
import toast from "../services/toast";

export default {
  name: "Login",
  data() {
    return {
      password: "",
      newPassword: "",
      newPasswordAgain: "",
      data: null,
      showError: false,
      error: null
    }
  },
  methods: {
    onSubmit(event) {
      if (this.newPassword !== this.newPasswordAgain) {
        event.preventDefault()
        this.showError = true
        this.error = "Paroles nesakrīt."
      } else {
        this.showError = false
        event.preventDefault()
        axios
          .post("api/change-password", {
            password: this.password,
            newPassword: this.newPassword,
            sessionId: sessionStorage.getItem("sessionId"),
          })
          .then((response) => {
            this.data = response.data;

            if (response.data.Errors !== undefined) {
              toast.error(response.data.Errors[0].ClientMessage)
            } else {
              this.$store.commit("setExpiryTime")

              if (this.$store.state.kiest.length == 1) {
                //window.location.href = "/#/posts";
              } else {
                //window.location.href = "/#/iestades";
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.row {
  padding-top: 100px;
}
</style>
