<template>
  <div class="content">
    <div class="row">
      <div class="col-md-2 offset-2">
        <b-button class="submit-button" @click="back">Atpakaļ</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <b-form v-show="!success" @submit="onSubmit">
          <b-form-group
            id="name-group"
            label="Lietotājvārds"
            label-for="name-input"
            description="Ievadiet savu lietotājvārdu"
          >
            <b-form-input
              :state="usernameValidation"
              v-model="username"
              id="name-input"
              type="text"
              maxLength="30"
              required
              placeholder="janis.berzins"
            ></b-form-input>
            <b-form-invalid-feedback :state="usernameValidation"
              >Jūsu lietotājvārdam jāsatur 4-20 simboli</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="email-group"
            label="E-pasts"
            label-for="email-input"
            description="Ievadiet savu e-pasta adresi"
          >
            <b-form-input
              :state="emailValidation"
              v-model="email"
              id="email-input"
              type="text"
              maxLength="50"
              required
              placeholder="janis.berzins@csdd.gov.lv"
            ></b-form-input>
            <b-form-invalid-feedback :state="emailValidation"
              >Jūsu e-pastam jāsatur simboli '@' un
              '.'.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-button type="submit" class="submit-button"
            >Atjaunot paroli</b-button
          >
        </b-form>
        <h3 v-show="success">
          Uz jūsu e-pastu tika nosūtīta informācija par tālākajām darbībam.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import toast from "../services/toast";

export default {
  name: "PasswordReset",
  data() {
    return {
      emailReg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      username: "",
      email: "",
      data: null,
      success: false,
    };
  },
  methods: {
    back() {
      window.location.href = "#/";
    },
    onSubmit(event) {
      event.preventDefault();

      axios
        .post("api/password-reset", {
          username: this.username,
          email: this.email,
          sessionId: sessionStorage.getItem("sessionId"),
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage);
          } else {
            this.success = true;
          }

          this.data = response.data;

          setTimeout(function () {
            window.location.href = "#/";
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    usernameValidation() {
      return this.username.length > 3 && this.username.length < 21;
    },
    emailValidation() {
      return this.emailReg.test(this.email);
    },
  },
};
</script>

<style scoped>
h3 {
  margin-top: 50px;
}

.content {
  margin-top: 25px;
}
</style>
