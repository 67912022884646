<template>
  <div class="pieteikumi">
    <day-pieteikumi v-on:new-clicked="newClicked" ref="dayRef" />
  </div>
</template>

<script>
import toast from "../services/toast"
import "izitoast/dist/css/iziToast.min.css"
import iZiToast from "izitoast"

import NewPieteikums from "./NewPieteikums.vue"
import DayPieteikumi from "./DayPieteikumi.vue"

export default {
  name: "Pieteikumi",
  components: {
    NewPieteikums,
    DayPieteikumi,
  },
  data() {
    return {
      pieteikumi: null,
      searchUzvards: "",
    };
  },
  methods: {
    newClicked(e) {
      this.$emit('new-clicked', e)
    },
    formatDate(pDate) {
      pDate = pDate.split("T")[0];
      let yyyy = pDate.split("-")[0].padStart(2, "0");
      let mm = pDate.split("-")[1].padStart(2, "0");
      let dd = pDate.split("-")[2].split(" ")[0];
      return dd + "." + mm + "." + yyyy;
    },
    formatTime(pDate) {
      pDate = pDate.split("T")[1];
      return pDate.slice(0, 5);
    },
    formatDateTime() {
      this.date.setHours(this.time.HH, this.time.mm, 0);
      return new Date(
        this.date.getTime() - this.date.getTimezoneOffset() * 60000
      )
        .toJSON()
        .toString()
        .slice(0, 19);
    },
    ieskaititQuestion(item) {
      var vue = this;
      iZiToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: "once",
        id: "question",
        zindex: 999,
        title: "",
        message: "Vai vēlaties atzīmēt nodarbības apmeklējumu?",
        position: "center",
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              vue.onIeskaititClick(item);
            },
          ],
          [
            "<button>Nē</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
            },
          ],
        ],
      });
    },
    onIeskaititClick(pieteikums) {
      axios
        .post("api/upd-uis-ubs-atz", {
          sessionId: sessionStorage.getItem("sessionId"),
          ubs_id: pieteikums.ubs_id,
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage);
          } else {
            toast.success("Uzdotā darbība izpildīta veiksmīgi");
            this.loadPieteikumi();
          }
        })
        .catch((error) => console.log(error));
    },
    deleteQuestion(item) {
      var vue = this;
      iZiToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: "once",
        id: "question",
        zindex: 999,
        title: "",
        message: "Vai tiešām vēlaties dzēst šo nodarbību?",
        position: "center",
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              vue.onDeleteClick(item);
            },
          ],
          [
            "<button>Nē</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
            },
          ],
        ],
      });
    },
    onDeleteClick(pieteikums) {
      axios
        .post("api/del-uis-ubs", {
          sessionId: sessionStorage.getItem("sessionId"),
          ubs_id: pieteikums.ubs_id,
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage);
          } else {
            toast.success("Uzdotā darbība izpildīta veiksmīgi");
            this.loadPieteikumi();
          }
        })
        .catch((error) => console.log(error));
    },
    loadPieteikumi() {
      axios
        .post("api/qry-uis-ubs", {
          sessionId: sessionStorage.getItem("sessionId"),
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessage);
          } else {
            this.pieteikumi = response.data.rowset;
          }
        })
        .catch((error) => console.log(error));
    },
  },
  computed: {
    filteredPieteikumiName: function () {
      if (this.pieteikumi !== undefined && this.pieteikumi !== null) {
        let result = [];

        result = this.pieteikumi.filter((item) => {
          return item.uzvards
            .toLowerCase()
            .includes(this.searchUzvards.toLowerCase());
        });

        return result;
      } else return [];
    },
  },
  watch: {
    person: function (newValue, oldValue) {
      this.error = null;
      this.personInfo = null;
    },
    date: function (newValue, oldValue) {
      this.error = null;
      this.personInfo = null;
    },
  },
  mounted() {
    this.loadPieteikumi();
  },
};
</script>

<style scoped>
.submit-button {
  width: 85px;
  margin-top: 5px;
}

.saraksts {
  margin-top: 50px;
}
</style>
