<template>
  <div class="container">
    <div v-show="!this.$store.state.loggedIn" style="margin-top: 50px; max-width: 420px; margin: 0 auto;">
      <img src="/images/CSDD_Logo_RGB_Zals.png" alt="" style="width: 140px; margin-left: -20px; margin-bottom: 25px">
      <b-form @submit="onSubmit">
        <b-form-group
          id="username-group"
          label="Lietotājvārds"
          label-for="username-input"
          description="Ievadiet savu lietotājvārdu"
        >
          <b-form-input
            id="username-input"
            v-model="form.username"
            type="text"
            maxLength="30"
            required
            oninvalid="this.setCustomValidity('Lūdzu aizpildiet šo lauku.')"
            onchange="this.setCustomValidity('')"
            placeholder="janis.berzins"
            autocomplete="username"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="password-group"
          label="Parole"
          label-for="password-input"
          description="Ievadiet paroli"
        >
          <b-form-input
            id="password-group"
            v-model="form.password"
            type="password"
            required
            maxLength="64"
            oninvalid="this.setCustomValidity('Lūdzu aizpildiet šo lauku.')"
            onchange="this.setCustomValidity('')"
            placeholder="********"
            autocomplete="password"
          ></b-form-input>
        </b-form-group>
        <!--<a href="/#/password-reset">Atjaunot paroli</a>-->
        <div class="d-flex justify-content-between">
          <b-button style="width: 120px" type="submit" class="submit-button">
            Autorizēties
          </b-button>
          <button
            :style="{
              width: '120px'
            }"
            type="button"
            class="btn btn-outline-secondary"
            @click="passwordReset"
          >
            Atjaunot paroli
          </button>
        </div>
        <div class="mt-4 text-center w-100">
          <a class="link" href="https://e.csdd.lv/simplesaml/?project=brauc">
            <div class="w-100">Vienotā pieteikšanās</div>
            <img
              style="width: 200px;"
              src="/images/maxresdefault.jpg"
            />
          </a>
        </div>
        <div class="w-100 px-2" style="position: absolute; bottom: 45px; left: 0">
          <div class="d-flex justify-content-between" style="margin: 0 auto; max-width: 420px;">
            <b-button class="submit-button" @click="openDocument"
              >Pieejas tiesību izveides pamācība
            </b-button>
            <b-button class="submit-button" @click="openManevri"
              >Speciālie manevri
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import toast from "../services/toast";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      latvija_logo: require("../assets/maxresdefault.jpg"),
      data: null,
    };
  },
  methods: {
    openDocument() {
      window.open("images/Instruktoru_el_tiesibu_izveide.pdf");
    },
    openManevri() {
      window.open("images/Specialie_manevri_autoskolam.pdf");
    },
    passwordReset() {
      window.location.href = "#/password-reset";
    },
    onSubmit(event) {
      this.showError = false;
      event.preventDefault();
      axios
        .post("api/login", {
          username: this.form.username,
          password: this.form.password,
        })
        .then((response) => {
          this.data = response.data;

          if (this.data.Errors !== undefined) {
            toast.error(this.data.Errors[0].ClientMessage);
          } else {
            //this.$store.commit("change");
            this.$store.state.loggedIn = true;
            sessionStorage.setItem("sessionId", this.data.rowset[0].sessionid);
            this.$store.state.sessionId = this.data.rowset[0].sessionid;
            this.$store.state.fpda = this.data.rowset[0].fpda;

            this.$store.commit("setName", this.data.rowset[0].vards);
            this.$store.commit("setSurname", this.data.rowset[0].uzvards);
            this.$store.commit("setEmail", this.data.rowset[0].epasts);
            this.$store.commit("setPm", this.data.rowset[0].pm);

            this.$store.commit("setExpiryTime");

            this.$store.commit("setTiesibas", this.data.rowset[0].kt_kodi);


              this.$store.commit("setTiesibas", this.data.rowset[0].kt_kodi);
              this.$store.commit(
                "setKiest_id",
                this.data.rowset[0].kiest[0].id
              );


            window.location.href = "#/news";
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
