<template>
  <div class="pieteikumi">
    <b-form @submit="onSubmit" style="margin: 0 auto; max-width: 420px;">
      <b-form-group
        id="input-group-code"
        label="Personas kods:"
        label-for="input-code-one"
      >
        <b-form-input
          id="input-code-one"
          placeholder="123456-12345"
          v-mask="'######-#####'"
          v-model="person"
          maxlength="12"
          oninvalid="this.setCustomValidity('Lūdzu aizpildiet šo lauku.')"
          onchange="this.setCustomValidity('')"
        ></b-form-input>
        <b-button
          type="button"
          :disabled="checkPk()"
          @click="getInfo()"
          class="submit-button w-100"
        >
          Meklēt
        </b-button>
      </b-form-group>
      <div v-if="!personInfo">
        <h2 class="page-title" style="margin: 0 auto; max-width: 420px;">Izlase</h2>
        <b-form-input
          class="mb-2"
          type="text"
          id="searchUzvards"
          v-model="searchUzvards"
          placeholder="Meklēt pēc uzvārda"
        />
        <div
          v-for="item in selection.filter((el) => el.uzvards.toLowerCase().includes(searchUzvards.toLowerCase()))"
          class="border pl-1 selection-item d-flex justify-content-between mb-2"
        >
          <div @click="error = null; selectPerson(item)">{{ item.vards + ' ' + item.uzvards + ', ' + item.kapmv_nos + ' ' + item.ugru_num }}</div>
          <b-button variant="danger" size="sm" @click="removeSelectionQuestion(item)">Dzēst</b-button>
        </div>
      </div>
      <div v-else>
        <h4 class="d-flex justify-content-between">
          <b>{{ personInfo.vards + " " + personInfo.uzvards }}</b>
          <b-button
            v-if="!selection.find((el) => el.ukur_id == personInfo.ukur_id)"
            class="submit-button text-nowrap"
            size="sm"
            @click="changeSelection(false)"
            style="min-width: 120px; margin-left: 10px; margin-top: -3px; max-height: 30px;"
          >
            Pievienot izlasei
          </b-button>
          <b-button
            v-else
            variant="danger"
            class="text-nowrap"
            size="sm"
            @click="removeSelectionQuestion(personInfo)"
            style="min-width: 120px; margin-left: 10px; margin-top: -3px; max-height: 30px;"
          >
            Noņemt no izlases
          </b-button>
        </h4>
      </div>
      <b-form-group v-if="foundPerson">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Datums</th>
              <th scope="col">Laiks</th>
              <th scope="col">Stundas</th>
              <th scope="col">Auto RN</th>
              <th scope="col">Tēma</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lesson, index) in pastLessons" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ formatDate(lesson.datums) }}</td>
              <td>{{ formatTime(lesson.datums) }}</td>
              <td>{{ lesson.br_stundas }}</td>
              <td>{{ lesson.rn }}</td>
              <td>{{ lesson.kbnt_kods }}</td>
            </tr>
          </tbody>
        </table>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import toast from "../services/toast";
import "izitoast/dist/css/iziToast.min.css";
import iZiToast from "izitoast";

import TheMask from "vue-the-mask";

import Datepicker from "vuejs-datepicker";
import { en, lv } from "vuejs-datepicker/dist/locale";

import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "NewPieteikums",
  components: {
    TheMask,
    Datepicker,
    VueTimepicker,
  },
  props: ["ukur_id"],
  data() {
    return {
      lv: lv,
      person: "",
      date: new Date(),
      time: null,
      lessonLength: "2",
      lessonTheme: null,
      allowedDateFrom: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
      allowedDateTo: new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate()
        )
      ),
      pastLessons: null,
      personInfo: null,
      lessonInfo: null,
      rn: null,
      error: null,
      foundPerson: false,
      selection: [],
      searchUzvards: ''
    }
  },
  methods: {
    removeSelectionQuestion(item) {
      var vue = this

      iZiToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: "once",
        id: "question",
        zindex: 999,
        title: "",
        message:
          "Vai vēlaties dzēst " + item.vards + ' ' + item.uzvards + ', ' + item.kapmv_nos + ' ' + item.ugru_num + " no  izlases?",
        position: "center",
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
              vue.changeSelection(true, item.ukur_id)
            },
          ],
          [
            "<button>Nē</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
            }
          ]
        ]
      })
    },
    selectPerson(person) {
      this.personInfo = person
      this.foundPerson = true

      axios
        .post("api/qry-ukur-ubs-v", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: this.personInfo.ukur_id
        })
        .then((response) => {
          console.log("pastLessons", response)
          if (response.data.Errors !== undefined) {
            this.error = response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage
          } else {
            this.pastLessons = response.data.rowset
          }
        })
        .catch((error) => console.log(error))
    },
    changeSelection(remove, ukur_id = null) {
      axios
        .post("api/ins-uisk", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: ukur_id ?? this.personInfo.ukur_id,
          dzest: remove
        })
        .then((response) => {
          console.log("ins-uisk", response)

          if (response.data.Errors !== undefined) {
            this.error = response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage
          } else {
            toast.success('Izlase veiksmīgi atjaunota')

            this.loadSelection()
          }
        })
        .catch((error) => console.log(error))
    },
    loadSelection() {
      axios
        .post("api/qry-uisk", {
          sessionId: sessionStorage.getItem("sessionId")
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            this.error = response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage
          } else {
            this.selection = response.data.rowset
          }
        })
        .catch((error) => console.log(error))
    },
    checkPk() {
      return this.person.length == 12 ? false : true;
    },
    checkForm() {
      if (
        this.time !== null &&
        this.time.HH !== "" &&
        this.time.mm !== "" &&
        this.lessonTheme !== null &&
        this.rn !== null &&
        this.rn !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    formatDate(pDate) {
      pDate = pDate.split("T")[0];
      let yyyy = pDate.split("-")[0].padStart(2, "0");
      let mm = pDate.split("-")[1].padStart(2, "0");
      let dd = pDate.split("-")[2].split(" ")[0];
      return dd + "." + mm + "." + yyyy;
    },
    formatTime(pDate) {
      pDate = pDate.split("T")[1];
      return pDate.slice(0, 5);
    },
    formatDateTime() {
      this.date.setHours(this.time.HH, this.time.mm, 0);
      return new Date(
        this.date.getTime() - this.date.getTimezoneOffset() * 60000
      )
        .toJSON()
        .toString()
        .slice(0, 19);
    },
    persCode() {
      return this.person.replace("-", "");
    },
    onSubmit() {
      event.preventDefault();

      if (this.personInfo !== null && this.personInfo.ukur_id !== null) {
        let loader = this.$loading.show({
          loader: "dots",
          color: "#365140",
        });

        axios
          .post("api/ins-uis-ubs", {
            sessionId: sessionStorage.getItem("sessionId"),
            ukur_id: this.personInfo.ukur_id,
            datums: this.formatDateTime(),
            br_stundas: this.lessonLength,
            kbnt_kods: this.lessonTheme,
            rn: this.rn,
          })
          .then((response) => {
            if (response.data.Errors !== undefined) {
              //toast.error(response.data.Errors[0].ClientMessage);
              this.error =
                response.data.Errors[0].ClientMessageCode +
                " " +
                response.data.Errors[0].ClientMessage;
            } else {
              toast.success("Uzdotā darbība izpildīta veiksmīgi");
              if (this.ukur_id !== undefined && this.ukur_id !== null) {
                this.getInfoUkur();
              } else {
                this.getInfo();
              }
              //this.$store.commit("setLastPerson", response.data.rowset[0].id);
            }
            loader.hide();
          })
          .catch((error) => console.log(error));
      }
    },
    getInfo() {
      //let date = this.date.toJSON().split("T")[0];

      this.date = new Date();
      this.time = null;
      this.lessonLength = "2";
      this.lessonTheme = null;
      this.allowedDateFrom = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
      this.allowedDateTo = new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate()
        )
      );
      this.personInfo = null;
      this.lessonInfo = null;
      this.rn = null;
      this.error = null;
      this.foundPerson = false;

      axios
        .post("api/qry-uis-ubs-jauns", {
          sessionId: sessionStorage.getItem("sessionId"),
          pk: this.persCode(),
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            //toast.error(response.data.Errors[0].ClientMessage);
            toast.error(response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage)
          } else {
            this.personInfo = response.data["rowset-ukur"][0]
            this.lessonInfo = response.data["rowset-kbnt"]
            this.rn = response.data["rowset-rn"][0] !== undefined ? response.data["rowset-rn"][0].rn : null
            this.foundPerson = true

            axios
              .post("api/qry-ukur-ubs-v", {
                sessionId: sessionStorage.getItem("sessionId"),
                ukur_id: this.personInfo.ukur_id,
              })
              .then((response) => {
                if (response.data.Errors !== undefined) {
                  this.error =
                    response.data.Errors[0].ClientMessageCode +
                    " " +
                    response.data.Errors[0].ClientMessage
                } else {
                  this.pastLessons = response.data.rowset
                }
              })
              .catch((error) => console.log(error))
          }
        })
        .catch((error) => console.log(error))
    },
    getInfoUkur() {
      this.date = new Date()
      this.time = null
      this.lessonLength = "2"
      this.lessonTheme = null
      this.allowedDateFrom = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
      this.allowedDateTo = new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate()
        )
      )
      this.personInfo = null
      this.lessonInfo = null
      this.rn = null
      this.error = null
      this.foundPerson = false

      axios
        .post("api/qry-uis-ubs-jauns", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: this.ukur_id
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            toast.error(response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage)
          } else {
            this.personInfo = response.data["rowset-ukur"][0]
            this.lessonInfo = response.data["rowset-kbnt"]
            this.rn = response.data["rowset-rn"][0] !== undefined ? response.data["rowset-rn"][0].rn : null
            this.foundPerson = true

            axios
              .post("api/qry-ukur-ubs-v", {
                sessionId: sessionStorage.getItem("sessionId"),
                ukur_id: this.personInfo.ukur_id
              })
              .then((response) => {
                if (response.data.Errors !== undefined) {
                  toast.error(response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage)
                } else {
                  this.pastLessons = response.data.rowset
                }
              })
              .catch((error) => console.log(error))
          }
        })
        .catch((error) => console.log(error))
    },
    setDate(event) {
      this.date = event
    }
  },
  mounted() {
    this.loadSelection()

    if (this.ukur_id !== undefined && this.ukur_id !== null) {
      this.getInfoUkur()
    }
  }
}
</script>

<style scoped>
.submit-button {
  width: 85px;
  margin-top: 5px;
}

.selection-item {
  border-radius: 5px;
  font-size: 16px;
}

.selection-item:hover {
  background-color: #365140;
  color: white;
  cursor: pointer;
}
</style>
