<template>
  <div class="content">
    <h2 class="page-title" style="margin: 0 auto; max-width: 420px;">Braukšanas nodarbību vēsture</h2>
    <History style="padding-right: 5px; margin-top: 15px" />
  </div>
</template>

<script>
import History from "../components/History.vue"
import iZiToast from "izitoast"

export default {
  name: "HistoryView",
  components: {
    History
  },
  data() {
    return {
      isInactive: false,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null
    }
  },
  methods: {
    logout() {
      axios
        .post("api/logout", {
          sessionId: this.$store.sessionId
        })
        .then(response => {
          if (response.data.Errors !== undefined) {
            if (
              response.data.Errors[0].ClientMessageCode !== "F-00004" &&
              response.data.Errors[0].ClientMessageCode !== "F-00013"
            ) {
              toast.error(
                response.data.Errors[0].ClientMessageCode +
                  " " +
                  response.data.Errors[0].ClientMessage
              )
            }
          }
        })
        .catch(error => console.log(error))

      sessionStorage.clear()
      this.$store.commit("change")
      window.location.href = "#/"

      this.$store.commit("logout")
    },
    activateActivityTracker() {
      window.addEventListener("mousemove", this.resetUserActivityTimeout)
      window.addEventListener("scroll", this.resetUserActivityTimeout)
      window.addEventListener("keydown", this.resetUserActivityTimeout)
      window.addEventListener("resize", this.resetUserActivityTimeout)
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout)
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction()
      }, 1500000)
    },
    userActivityThrottler() {
      if (!userActivityThrottlerTimeout) {
        userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()

          clearTimeout(userActivityThrottlerTimeout)
          userActivityThrottlerTimeout = null
        }, 1000)
      }
    },
    inactiveUserAction() {
      window.removeEventListener("mousemove", this.userActivityThrottler)
      window.removeEventListener("scroll", this.userActivityThrottler)
      window.removeEventListener("keydown", this.userActivityThrottler)
      window.removeEventListener("resize", this.userActivityThrottler)

      var vue = this

      iZiToast.show({
        timeout: 301000,
        close: false,
        drag: false,
        overlay: true,
        pauseOnHover: false,
        displayMode: "replace",
        id: "question",
        zindex: 999999999,
        title: "Uzmanību!",
        message:
          "Jūsu sesijas laiks tulīt beigsies, vai vēlaties turpināt darbu?",
        position: "center",
        onClosed: function() {
          if (vue.$store.getters.isExpired) {
            vue.logout()
            vue.$store.commit("setExpiryTime")
          } else vue.$store.commit("setExpiryTime")
        },
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function(instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
              vue.$store.commit("setExpiryTime")
              vue.activateActivityTracker()
              vue.resetUserActivityTimeout()
            },
            true
          ],
          [
            "<button>Nē</button>",
            function(instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
              vue.logout()
              window.location.reload()
            }
          ]
        ]
      })
    }
  },
  beforeMount() {
    this.activateActivityTracker()
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.userActivityThrottler)
    window.removeEventListener("scroll", this.userActivityThrottler)
    window.removeEventListener("keydown", this.userActivityThrottler)
    window.removeEventListener("resize", this.userActivityThrottler)

    clearTimeout(this.userActivityTimeout)
    clearTimeout(this.userActivityThrottlerTimeout)
  },
  mounted() {
    if (!this.$store.state.loggedIn) {
      window.location.href = "#/"
      
      setTimeout(() => {
        location.reload()
      }, 100)
    }
  }
}
</script>