<template>
  <div class="form">
    <div class="col-md-6 offset-md-3">
      <b-form @submit="chooseKiest">
        <b-form-group>
          <select class="custom-select" name="kiest" id="kiest-select" v-model="kiest_nos">
            <option
              v-for="kiest in $store.state.kiest"
              :key="kiest.id"
              :selected="selected(kiest.nos)"
              :value="kiest.nos"
            >{{ kiest.nos }}</option>
          </select>
        </b-form-group>

        <b-button type="submit" class="submit-button">Izvēlēties iestādi</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import toast from "../services/toast";

export default {
  name: "Kiest",
  data() {
    return {
      kiest_nos: this.$store.state.kiest_nos,
      kiest_id: "",
    };
  },
  methods: {
    chooseKiest() {
      event.preventDefault();
      
      this.$store.state.kiest.forEach((kiest) => {
        if (kiest.nos == this.kiest_nos) {
          axios
            .post("api/update-kiest", {
              sessionId: sessionStorage.getItem("sessionId"),
              kiest_id: kiest.id,
            })
            .then((response) => {
              if (response.data.Errors !== undefined) {
                toast.error(response.data.Errors[0].ClientMessage);
              } else {
                this.$store.state.tiesibas = response.data.rowset[0].kt_kodi;
                this.$store.commit("setKiest_nos", this.kiest_nos);
                this.$store.commit("setKiest_id", kiest.id);
                toast.success("Iestāde izvēlēta veiksmīgi");
                window.location.href = "#/pieteikumi";
                window.location.reload();
              }
            })
            .catch((error) => console.log(error));
        }
      });
    },
    selected(nos) {
      if (nos == this.$store.state.kiest_nos) {
        return true;
      }
    },
  },
  mounted() {
    if (this.$store.state.kiest.length == 1) {
      window.location.href = "#/pieteikumi";
    } else {
      this.kiest_nos = this.$store.state.kiest[0].nos
    }
  },
};
</script>