<template>
  <div class="pieteikumi" style="margin: 0 auto; max-width: 420px;">
    <b-form @submit="onSubmit">
      <b-form-group
        v-if="!personInfo"
        id="input-group-code"
        label="Personas kods:"
        label-for="input-code-one"
      >
        <b-form-input
          id="input-code-one"
          placeholder="123456-12345"
          v-mask="'######-#####'"
          v-model="person"
          maxlength="12"
          oninvalid="this.setCustomValidity('Lūdzu aizpildiet šo lauku.')"
          onchange="this.setCustomValidity('')"
        ></b-form-input>
        <b-button
          type="button"
          :disabled="checkPk()"
          @click="getInfo()"
          class="submit-button w-100"
        >
          Meklēt
        </b-button>
      </b-form-group>
      <b-form-group v-if="!foundPerson && personChoices !== null">
        <h4>
          <b>
            {{ personChoices[0].vards + " " + personChoices[0].uzvards }}
          </b>
        </h4>
        <div class="d-flex justify-content-between">
          <b style="white-space: nowrap; margin-top: 10px">
            {{ personChoices[0].kapmv_nos + " " + personChoices[0].ugru_num }}
          </b>
          <b-button class="submit-button" @click="selectPerson(personChoices[0])">
            Izvēlēties
          </b-button>
        </div>
        <b-button
          class="submit-button"
          style="width: 100%; margin-top: 25px; white-space: nowrap"
          type="button"
          data-toggle="collapse"
          data-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Citas grupas
        </b-button>
        <div class="collapse" id="collapseExample">
          <div class="card card-body" style="min-height: 50px;">
            <div
              v-for="(person, index) in personChoices.slice(1)"
              :key="index"
              class="d-flex justify-content-between w-100"
            >
              <div style="padding-top: 10px">
                <b style="white-space: nowrap">{{
                  person.kapmv_nos + " " + person.ugru_num
                }}</b>
              </div>
              <b-button class="submit-button" style="white-space: nowrap" @click="selectPerson(person)">
                Izvēlēties
              </b-button>
            </div>
          </div>
        </div>
      </b-form-group>
      <b-form-group v-if="foundPerson">
        <div class="d-flex">
          <h4>
            <b>{{ personInfo.vards + " " + personInfo.uzvards }}</b>
          </h4>
          <b-button
            v-if="!selection.find((el) => el.ukur_id == personInfo.ukur_id)"
            class="submit-button text-nowrap"
            size="sm"
            @click="changeSelection(false)"
            style="min-width: 120px; margin-left: 10px; margin-top: -3px; max-height: 30px;"
          >
            Pievienot izlasei
          </b-button>
          <b-button
            v-else
            variant="danger"
            class="text-nowrap"
            size="sm"
            @click="removeSelectionQuestion(personInfo)"
            style="min-width: 120px; margin-left: 10px; margin-top: -3px; max-height: 30px;"
          >
            Noņemt no izlases
          </b-button>
        </div>
        <div class="mt-2">Braukšanas nodarbības sākuma laiks:</div>
        <datepicker
          class="w-100"
          :input-class="'w-100'"
          :value="date"
          name="date"
          :language="lv"
          :monday-first="true"
          format="dd MMMM yyyy"
          :required="true"
          v-on:selected="setDate"
          :disabled-dates="{ to: allowedDateFrom, from: allowedDateTo }"
        ></datepicker>
        <vue-timepicker
          class="w-100 mt-2"
          :minute-interval="5"
          :hour-range="[[6, 24]]"
          hide-disabled-hours
          v-model="time"
          :input-width="'100%'"
        ></vue-timepicker>
        <div class="mt-2">
          Nodarbības ilgums:
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            value="1"
            v-model="lessonLength"
          />
          <label class="form-check-label" for="flexRadioDefault1">
            1 mācību stunda (45min)
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            value="2"
            v-model="lessonLength"
            checked
          />
          <label class="form-check-label" for="flexRadioDefault2">
            2 mācību stundas (90min)
          </label>
        </div>
        <!--Nodarbības ilgums:
        <select
          class="form-select"
          style="width: 100%"
          v-model="lessonLength"
          aria-label="Default select example"
        >
          <option value="1">1 mācību stunda</option>
          <option value="2">2 mācību stundas</option>
        </select>-->
        <!--<b-form-group
          label="Nodarbības ilgums:"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="radio-group-1"
            v-model="lessonLength"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
            ><b-form-radio
              v-model="lessonLength"
              name="some-radios"
              value="1"
              >1 mācību stunda (45min)</b-form-radio
            >
            <b-form-radio
              v-model="lessonLength"
              name="some-radios"
              value="2"
              :checked="true"
              >2 mācību stundas (90min)</b-form-radio
            ></b-form-radio-group
          >
        </b-form-group>-->
        <!--<div
          class="mt-2"
          v-if="pastLessons !== null && pastLessons.length !== 0"
        >
          Kursants jau mācijies sekojošās tēmas:
          <ul>
            <li v-for="(lesson, index) in pastLessons" :key="index">
              {{ lesson.kbnt_kods }}
            </li>
          </ul>
        </div>-->
        <div class="mt-2">
          <label for="laukums-checkbox">Laukums: (Tikai A kategorijai)</label>
          <input type="checkbox" id="laukums-checkbox" v-model="laukums">
        </div>
        <!-- Nodarbības tēma:
        <select
          style="max-width: 100%"
          class="form-select"
          v-model="lessonTheme"
          aria-label="Default select example"
        >
          <option
            v-for="theme in lessonInfo"
            :key="theme.kbnt_kods"
            :value="theme.kbnt_kods"
          >
            {{ theme.kbnt_kods + " - " + theme.kbnt_nos }}
          </option>
        </select> -->
        <b-form-input
          class="mt-2"
          id="input-rn"
          placeholder="Auto reģistrācijas numurs"
          v-model="rn"
          maxlength="8"
          required
          oninvalid="this.setCustomValidity('Lūdzu aizpildiet šo lauku.')"
          onchange="this.setCustomValidity('')"
          @input="rn = rn.toUpperCase()"
        ></b-form-input>
      </b-form-group>
      <div style="color: red; max-width: 400px">{{ error }}</div>
      <b-button
        type="submit"
        v-if="foundPerson"
        :disabled="checkForm()"
        class="submit-button w-100"
      >
        Pievienot
      </b-button>
      <b-button
        v-if="personInfo"
        class="w-100 mt-2"
        variant="outline-secondary"
        @click="personInfo = null; foundPerson = false"
      >
        Atpakaļ
      </b-button>
    </b-form>
    <div v-if="!personInfo">
      <h2 class="page-title" style="margin: 0 auto; max-width: 420px;">Izlase</h2>
      <b-form-input
        class="mb-2"
        type="text"
        id="searchUzvards"
        v-model="searchUzvards"
        placeholder="Meklēt pēc uzvārda"
      />
      <div
        v-for="item in selection.filter((el) => el.uzvards.toLowerCase().includes(searchUzvards.toLowerCase()))"
        class="border pl-1 selection-item d-flex justify-content-between mb-2"
      >
        <div @click="error = null; selectPerson(item)">{{ item.vards + ' ' + item.uzvards + ', ' + item.kapmv_nos + ' ' + item.ugru_num }}</div>
        <b-button variant="danger" size="sm" @click="removeSelectionQuestion(item)">Dzēst</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import toast from "../services/toast"
import "izitoast/dist/css/iziToast.min.css"
import iZiToast from "izitoast"

import TheMask from "vue-the-mask"

import Datepicker from "vuejs-datepicker"
import { en, lv } from "vuejs-datepicker/dist/locale"

import VueTimepicker from "vue2-timepicker"
import "vue2-timepicker/dist/VueTimepicker.css"

export default {
  name: "NewPieteikums",
  components: {
    TheMask,
    Datepicker,
    VueTimepicker,
  },
  props: ["ukur_id"],
  data() {
    return {
      lv: lv,
      person: "",
      date: new Date(),
      time: null,
      lessonLength: "2",
      lessonTheme: null,
      allowedDateFrom: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
      allowedDateTo: new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate()
        )
      ),
      pastLessons: null,
      personInfo: null,
      lessonInfo: null,
      rn: null,
      error: null,
      foundPerson: false,
      personChoices: null,
      laukums: false,
      selection: [],
      searchUzvards: ''
    }
  },
  methods: {
    removeSelectionQuestion(item) {
      var vue = this

      iZiToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: "once",
        id: "question",
        zindex: 999,
        title: "",
        message:
          "Vai vēlaties dzēst " + item.vards + ' ' + item.uzvards + ', ' + item.kapmv_nos + ' ' + item.ugru_num + " no  izlases?",
        position: "center",
        buttons: [
          [
            "<button><b>Jā</b></button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
              vue.changeSelection(true, item.ukur_id)
            },
          ],
          [
            "<button>Nē</button>",
            function (instance, toast) {
              instance.hide({ transitionOut: "fadeOut" }, toast, "button")
            }
          ]
        ]
      })
    },
    checkPk() {
      return this.person.length == 12 ? false : true;
    },
    checkForm() {
      if (
        this.time !== null &&
        this.time.HH !== "" &&
        this.time.mm !== "" &&
        this.rn !== null &&
        this.rn !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    formatDate(pDate) {
      pDate = pDate.split("T")[0];
      let yyyy = pDate.split("-")[0].padStart(2, "0");
      let mm = pDate.split("-")[1].padStart(2, "0");
      let dd = pDate.split("-")[2].split(" ")[0];
      return dd + "." + mm + "." + yyyy;
    },
    formatTime(pDate) {
      pDate = pDate.split("T")[1];
      return pDate.slice(0, 5);
    },
    formatDateTime() {
      this.date.setHours(this.time.HH, this.time.mm, 0);
      return new Date(
        this.date.getTime() - this.date.getTimezoneOffset() * 60000
      )
        .toJSON()
        .toString()
        .slice(0, 19);
    },
    persCode() {
      return this.person.replace("-", "")
    },
    async onSubmit() {
      event.preventDefault()

      if (this.personInfo !== null && this.personInfo.ukur_id !== null) {
        let loader = this.$loading.show({
          loader: "dots",
          color: "#365140"
        })

        await axios
          .post("api/ins-uis-ubs", {
            sessionId: sessionStorage.getItem("sessionId"),
            ukur_id: this.personInfo.ukur_id,
            datums: this.formatDateTime(),
            br_stundas: this.lessonLength,
            kbnt_kods: this.laukums ? 'L' : null,
            rn: this.rn
          })
          .then((response) => {
            if (response.data.Errors !== undefined) {
              this.error = response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage
            } else {
              toast.success("Uzdotā darbība izpildīta veiksmīgi")

              /* if (this.ukur_id !== undefined && this.ukur_id !== null) {
                this.getInfoUkur()
              } else {
                this.getInfo()
              } */

              this.personInfo = null
              this.foundPerson = false
              this.personChoices = null
              this.person = ''
            }

            loader.hide()
          })
          .catch((error) => {
            console.log(error)

            loader.hide()
          })

        loader.hide()
      }
    },
    selectPerson(person) {
      this.personInfo = person
      this.foundPerson = true

      axios
        .post("api/qry-ukur-ubs-v", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: this.personInfo.ukur_id
        })
        .then((response) => {
          console.log("pastLessons", response)
          if (response.data.Errors !== undefined) {
            this.error = response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage
          } else {
            this.pastLessons = response.data.rowset
          }
        })
        .catch((error) => console.log(error))
    },
    changeSelection(remove, ukur_id = null) {
      axios
        .post("api/ins-uisk", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: ukur_id ?? this.personInfo.ukur_id,
          dzest: remove
        })
        .then((response) => {
          console.log("ins-uisk", response)

          if (response.data.Errors !== undefined) {
            this.error = response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage
          } else {
            toast.success('Izlase veiksmīgi atjaunota')

            this.loadSelection()
          }
        })
        .catch((error) => console.log(error))
    },
    getInfo() {
      //let date = this.date.toJSON().split("T")[0];

      this.date = new Date()
      this.time = null
      this.lessonLength = "2"
      this.lessonTheme = null
      this.allowedDateFrom = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
      this.allowedDateTo = new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate()
        )
      )
      this.personInfo = null
      this.lessonInfo = null
      this.rn = null
      this.error = null
      this.foundPerson = false

      axios
        .post("api/qry-uis-ubs-jauns", {
          sessionId: sessionStorage.getItem("sessionId"),
          pk: this.persCode()
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            //toast.error(response.data.Errors[0].ClientMessage);
            this.error =
              response.data.Errors[0].ClientMessageCode +
              " " +
              response.data.Errors[0].ClientMessage
          } else {
            if (response.data["rowset-ukur"].length > 1) {
              this.personChoices = response.data["rowset-ukur"]

              this.lessonInfo = response.data["rowset-kbnt"]
              this.rn =
                response.data["rowset-rn"][0] !== undefined
                  ? response.data["rowset-rn"][0].rn
                  : null
            } else {
              this.personInfo = response.data["rowset-ukur"][0]
              this.lessonInfo = response.data["rowset-kbnt"]
              this.rn =
                response.data["rowset-rn"][0] !== undefined
                  ? response.data["rowset-rn"][0].rn
                  : null
              this.foundPerson = true

              axios
                .post("api/qry-ukur-ubs-v", {
                  sessionId: sessionStorage.getItem("sessionId"),
                  ukur_id: this.personInfo.ukur_id
                })
                .then((response) => {
                  if (response.data.Errors !== undefined) {
                    this.error =
                      response.data.Errors[0].ClientMessageCode +
                      " " +
                      response.data.Errors[0].ClientMessage
                  } else {
                    this.pastLessons = response.data.rowset
                  }
                })
                .catch((error) => console.log(error))
            }
          }
        })
        .catch((error) => console.log(error))
    },
    getInfoUkur() {
      this.date = new Date()
      this.time = null
      this.lessonLength = "2"
      this.lessonTheme = null
      this.allowedDateFrom = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
      this.allowedDateTo = new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate()
        )
      )
      this.personInfo = null
      this.lessonInfo = null
      this.rn = null
      this.error = null
      this.foundPerson = false

      axios
        .post("api/qry-uis-ubs-jauns", {
          sessionId: sessionStorage.getItem("sessionId"),
          ukur_id: this.ukur_id
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            //toast.error(response.data.Errors[0].ClientMessage)
            this.error =
              response.data.Errors[0].ClientMessageCode +
              " " +
              response.data.Errors[0].ClientMessage
          } else {
            this.personInfo = response.data["rowset-ukur"][0]
            this.lessonInfo = response.data["rowset-kbnt"]
            this.rn =
              response.data["rowset-rn"][0] !== undefined
                ? response.data["rowset-rn"][0].rn
                : null
            this.foundPerson = true

            axios
              .post("api/qry-ukur-ubs-v", {
                sessionId: sessionStorage.getItem("sessionId"),
                ukur_id: this.personInfo.ukur_id
              })
              .then((response) => {
                if (response.data.Errors !== undefined) {
                  this.error =
                    response.data.Errors[0].ClientMessageCode +
                    " " +
                    response.data.Errors[0].ClientMessage
                } else {
                  this.pastLessons = response.data.rowset
                }
              })
              .catch((error) => console.log(error))
          }
        })
        .catch((error) => console.log(error))
    },
    setDate(event) {
      this.date = event
    },
    loadSelection() {
      axios
        .post("api/qry-uisk", {
          sessionId: sessionStorage.getItem("sessionId")
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            this.error = response.data.Errors[0].ClientMessageCode + " " + response.data.Errors[0].ClientMessage
          } else {
            this.selection = response.data.rowset
          }
        })
        .catch((error) => console.log(error))
    }
  },
  mounted() {
    if (this.ukur_id !== undefined && this.ukur_id !== null) {
      this.getInfoUkur()
    }

    this.loadSelection()
  }
}
</script>

<style scoped>
.submit-button {
  width: 85px;
  margin-top: 5px;
}

.selection-item {
  border-radius: 5px;
  font-size: 16px;
}

.selection-item:hover {
  background-color: #365140;
  color: white;
  cursor: pointer;
}
</style>
