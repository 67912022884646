<template>
  <div class="content" style="padding-left: 0">
    <Login />
  </div>
</template>

<script>
import Login from "../components/Login.vue";

export default {
  name: "HomeView",
  components: {
    Login,
  },
};
</script>
