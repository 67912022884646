import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('../views/ChangePasswordView.vue')
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () => import('../views/PasswordResetView.vue')
  },
  {
    path: '/password-reset/kods=:kods',
    name: 'PasswordResetContinue',
    component: () => import('../views/PasswordResetContinueView.vue'),
    props: true
  },
  {
    path: '/pieteikumi',
    name: 'Pieteikumi',
    component: () => import('../views/PieteikumiView.vue'),
  },
  {
    path: '/new-pieteikums',
    name: 'NewPieteikums',
    component: () => import('../views/NewPieteikumsView.vue'),
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/HistoryView.vue'),
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () => import('../views/DocumentationView.vue'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/NewsView.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../components/Logout.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
