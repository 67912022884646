<template>
  <div id="app">
    <div class="app-content" v-if="$store.state.fpda !== false">
      <div style="position: absolute; right: 5px; top: 0; font-size: 8px">
        {{ version }}
      </div>
      <sidebar-menu
        v-if="$store.state.loggedIn"
        :menu="menuWithoutKiest"
        :collapsed="true"
        @item-click="onMenuItemClick"
      />

      <b-alert
        v-model="$store.state.showCookies"
        variant="warning"
        dismissible
        @dismissed="cookiesDismissed"
        >Sīkdatnes palīdz mums veidot mūsu pakalpojumus. Izmantojot mūsu
        pakalpojumus, jūs piekrītat mūsu sīkdatņu izmantošanai.</b-alert
      >

      <!--<img
        :src="logo"
        v-if="$store.state.loggedIn == false"
        id="cornerLogo"
        style="position: absolute; left: -20px; top: 0px; width: 150px"
        alt="logo small"
      />
      <img
        :src="logo"
        v-if="$store.state.loggedIn == true"
        id="cornerLogo"
        style="position: absolute; left: 50px; top: 0px; width: 150px"
        alt="logo small"
      />-->

      <router-view />
    </div>
    <div class="fpda row" v-if="$store.state.fpda == false">
      <div
        class="col-md-4 offset-4 text-justify"
        style="color: red; font-size: 20px; margin-top: 25px"
      >
        <p>
          Apņemos saskaņā ar Fizisko personu datu aizsardzība likuma vai jebkura
          cita spēkā esošā datu aizsardzības regulējuma prasībām:
        </p>
        <ol>
          <li>
            nelikumīgi neapstrādāt, nesaglabāt, un neizpaust amata (darba)
            pienākumu veikšanas laikā iegūtos fizisko personu datus;
          </li>
          <li>
            pēc darba (dienesta) tiesisko attiecību izbeigšanas nelikumīgi
            neizpaust iegūtos fizisko personu datus.
          </li>
        </ol>
        <p>
          Esmu brīdināts (a), ka fizisko personu datu nelikumīgas izpaušanas
          gadījumā varu tikt saukts (a) pie normatīvajos aktos noteiktās
          atbildības.
        </p>
        <div id="approveCheckbox">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="approve"
            id="defaultCheck1"
          />
          <label class="form-check-label" for="defaultCheck1">
            Izlasīju un piekrītu
          </label>
        </div>
        <b-button class="submit-button" :disabled="!approve" @click="acceptFpda"
          >Turpināt</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import "../sass/_variables.scss"
import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss"
import toast from "./services/toast"
import "izitoast/dist/css/iziToast.min.css"
import iZiToast from "izitoast"
import { version } from "../../package.json"

export default {
  name: "App",
  components: {
    iZiToast,
  },
  data() {
    return {
      version: version,
      approve: false,
      mySVG: require("./assets/logo-csdd.svg"),
      logo: require("./assets/logo.png"),
      menu: [],
      menuWithoutKiest: [
        {
          header: true,
          title: "Galvenā izvēlne",
          hiddenOnCollapse: true,
        },
        {
          href: "/logout",
          title: "Beigt darbu",
          icon: "fa fa-times",
        },
      ],
    };
  },
  mounted() {
    if (this.$store.state.loggedIn !== false) {
      axios
        .post("api/session", {
          sessionId: this.$store.state.sessionId,
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            if (response.data.Errors[0].ClientMessageCode == "F-00003") {
              this.$store.state.loggedIn = false;
              window.location.href = "#/";
            }
          } else {
            sessionStorage.setItem("sessionId", this.$store.state.sessionId);
          }
        })
        .catch((error) => console.log(error));
    }

    if (this.$store.state.loggedIn == true) {
      if (this.$store.state.pm == 1) {
        window.location.href = "#/change-password";
      }

      this.menuWithoutKiest.pop();

      this.menuWithoutKiest.push(
        {
          href: "news",
          title: "Jaunumi un paziņojumi",
          icon: "fa fa-exclamation-circle",
        },
        {
          href: "/new-pieteikums",
          title: "Jauns pieteikums",
          icon: "fa fa-plus",
        }
      );

      this.menuWithoutKiest.push({
        href: "/pieteikumi",
        title: "Pieteikumi",
        icon: "fa fa-calendar",
      });

      this.menuWithoutKiest.push({
        href: "/history",
        title: "Nodarbību vēsture",
        icon: "fa fa-history",
      });

      this.menuWithoutKiest.push(
        {
          href: "/documentation",
          title: "Dokumentācija",
          icon: "fa fa-question",
        },
        {
          href: "/change-password",
          title: "Mainīt paroli",
          icon: "fa fa-cog",
        },
        {
          href: "/logout",
          title: "Beigt darbu",
          icon: "fa fa-times",
        }
      );

      if (this.$store.getters.isExpired) {
        this.logout();
        this.$store.commit("setExpiryTime");
      } else this.$store.commit("setExpiryTime");
    }
  },
  methods: {
    acceptFpda() {
      axios
        .post("api/fpda", {
          sessionId: this.$store.state.sessionId,
        })
        .then((response) => {
          this.$store.state.fpda = true;
        })
        .catch((error) => console.log(error));
    },
    onMenuItemClick(event, item, node) {
      if (node.active) {
        window.location.reload();
      }
    },
    logout() {
      axios
        .post("api/logout", {
          sessionId: this.$store.state.sessionId,
        })
        .then((response) => {
          if (response.data.Errors !== undefined) {
            if (
              response.data.Errors[0].ClientMessageCode !== "F-00004" &&
              response.data.Errors[0].ClientMessageCode !== "F-00013"
            ) {
              toast.error(response.data.Errors[0].ClientMessage);
            }
          }
        })
        .catch((error) => console.log(error));

      sessionStorage.clear();
      this.$store.commit("change");
      window.location.href = "#/";

      this.$store.commit("logout");
    },
    cookiesDismissed() {
      this.$store.commit("cookies");
    },
  },
};
</script>

<style>
.content {
  padding-left: 55px;
}

.card {
  padding: 5%;
  margin-bottom: 30px;
  text-align: left;
  min-height: 200px;
  min-width: 199px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.2s ease-in-out;
}

.last-person {
  border: 2px solid #365140;
}

.card:hover {
  transform: scale(1);
  cursor: default;
}

.cardTime {
  margin-bottom: 30px;
  text-align: left;
  min-height: 185px;
}

.card-header {
  background: #365140;
  color: white;
  border-radius: 2px;
}

#logo {
  width: 70px;
}

#logout {
  position: absolute;
  right: 0;
}

.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background: #365140 !important;
}

.v-sidebar-menu .vsm--mobile-bg {
  border: #2a2a2e 3px solid;
  border-radius: 5px;
}

.v-sidebar-menu .vsm--item.vsm--item_open {
  overflow-y: visible;
}

.vsm--dropdown.vsm--dropdown_mobile-item {
  overflow-y: hidden !important;
}

.navbar {
  background: #365140;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  margin-left: -45px;
  margin-top: -10px;
}

.navbar-nav {
  margin-left: 35px;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  margin-right: 20px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: lightgray;
}

.navbar-light .navbar-nav .nav-link:active {
  color: lightgray;
}

.navbar-light .navbar-nav .nav-link:visited {
  color: white;
}

.dropdown a:hover {
  background-color: #365140;
  color: white;
}

.dropdown a:active {
  background-color: #1E3829;
  color: white;
}

.submit-button {
  background-color: #365140;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.submit-button:hover {
  background-color: #1E3829;
}

.submit-button.disabled:hover {
  background-color: #6c757d;
}

.submit-button:focus {
  background-color: #1E3829;
}

.submit-button:active {
  background-color: #1E3829;
}

.apliecinajuma-button {
  background-color: #365140;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.apliecinajuma-button:hover {
  background-color: #1E3829;
}

.apliecinajuma-button.disabled:hover {
  background-color: #6c757d;
}

.apliecinajuma-button:focus {
  background-color: #1E3829;
}

.apliecinajuma-button:active {
  background-color: #1E3829;
}

.apliecinajuma-delete-button {
  background-color: #ce0000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.apliecinajuma-delete-button:hover {
  background-color: #f80000;
}

.apliecinajuma-delete-button.disabled:hover {
  background-color: #6c757d;
}

.apliecinajuma-delete-button:focus {
  background-color: #f80000;
}

.apliecinajuma-delete-button:active {
  background-color: #f80000;
}

.delete-button {
  background-color: #ce0000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.delete-button:hover {
  background-color: #f80000;
}

.delete-button.disabled:hover {
  background-color: #6c757d;
}

.delete-button:focus {
  background-color: #f80000;
}

.delete-button:active {
  background-color: #f80000;
}

.card-header {
  background: #365140;
  color: white;
  border-radius: 2px;
}

.page-title {
  margin-bottom: 25px;
}

#approveCheckbox {
  color: black;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 25px;
  font-size: 16px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(202, 202, 202) !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(202, 202, 202) !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgb(202, 202, 202) !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(202, 202, 202) !important;
}
</style>
