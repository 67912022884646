<template>
  <div class="content">
    <PasswordReset />
  </div>
</template>

<script>
import PasswordReset from "../components/PasswordReset.vue";

export default {
  name: "PasswordResetView",
  components: {
    PasswordReset,
  },
};
</script>
