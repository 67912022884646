import 'izitoast/dist/css/iziToast.min.css'
import iZitoast from 'izitoast'

const toast = {
    error: (message, title = 'Kļūda') => {
        return iZitoast.error({
            title: title,
            message: message,
            position: 'bottomCenter',
            timeout: 10000
        });
    },
    success: (message, title = '') => {
        return iZitoast.success({
            title: title,
            message: message,
            position: 'topCenter',
            timeout: 10000
        });
    }
};

export default toast;