require('./bootstrap');

import "babel-polyfill";
import 'whatwg-fetch';

if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
        value: function assign(target, varArgs) { // .length of function is 2
            'use strict';
            if (target === null || target === undefined) {
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource !== null && nextSource !== undefined) {
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true
    });
}

window.Vue = require('vue');

import Vue from 'vue';
import toast from './services/toast'

import 'vue-croppa/dist/vue-croppa.css'
import Croppa from 'vue-croppa'

//import pleaserotate from './services/pleaserotate'

Vue.use(Croppa)

import VueHtml2Canvas from 'vue-html2canvas';

Vue.use(VueHtml2Canvas);

Vue.config.errorHandler = function (err) {
    //err, vm, info - args
    if (err !== null && err !== undefined) {
        console.log(err);
        toast.error('Kļūda datu ielādē. Pārlādējiet lapu (CTRL + F5) un mēģiniet vēlreiz.');
    } else {
        toast.error('Globāla kļūda. Pārlādējiet lapu (CTRL + F5) un mēģiniet vēlreiz.');
    }
}


Vue.config.warnHandler = function (err) {
    //err, vm, trace - args
    if (err !== null && err !== undefined) {
        console.log(err);
        toast.error('Kļūda datu ielādē. Pārlādējiet lapu (CTRL + F5) un mēģiniet vēlreiz.');
    } else {
        toast.error('Globāla kļūda. Pārlādējiet lapu (CTRL + F5) un mēģiniet vēlreiz.');
    }
};

window.onerror = function (message) {
    //message , source, line, column, error - args
    if (message !== null && message !== undefined) {
        toast.error(message);
    } else {
        toast.error('Globāla kļūda. Pārlādējiet lapu (CTRL + F5) un mēģiniet vēlreiz.');
    }
}

import 'es6-promise/auto'

import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {
    BootstrapVue,
    IconsPlugin
} from 'bootstrap-vue'
import router from './router'
import App from './App.vue'
import 'vue-search-select/dist/VueSearchSelect.css'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import './views/ChangePasswordView.vue'
import './views/HomeView.vue'
import './views/KiestView.vue'
import './views/PieteikumiView.vue'
import './views/NewPieteikumsView.vue'
import './components/Logout.vue'
import './views/PasswordResetView.vue'
import './views/PasswordResetContinueView.vue'
import './views/DocumentationView.vue'
import './views/HistoryView.vue'
import './views/NewsView.vue'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

import ReadMore from 'vue-read-more';
Vue.use(ReadMore);

import TheMask from "vue-the-mask";

import VueMq from 'vue-mq'

Vue.use(VueMq, {
    breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
    }
})

Vue.use(TheMask)

Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSidebarMenu)

function initialState() {
    return {
        loggedIn: false,
        name: '',
        surname: '',
        email: '',
        expiryDate: null,
        pm: null,
        sessionId: null,
        tiesibas: null,
        fpda: null,
        lastPerson: null
    }
}

const store = new Vuex.Store({
    state: {
        loggedIn: false,
        name: '',
        surname: '',
        email: '',
        expiryDate: null,
        pm: null,
        showCookies: true,
        sessionId: null,
        tiesibas: null,
        fpda: null,
        lastPerson: null
    },
    getters: {
        isExpired: state => (new Date(state.expiryDate) < new Date())
    },
    mutations: {
        change(state) {
            state.loggedIn = !state.loggedIn
        },
        setName(state, payload) {
            state.name = payload
        },
        setSurname(state, payload) {
            state.surname = payload
        },
        setEmail(state, payload) {
            state.email = payload
        },
        setPm(state, payload) {
            state.pm = payload
        },
        setTiesibas(state, payload) {
            state.tiesibas = payload
        },
        setLastPerson(state, payload) {
            state.lastPerson = payload
        },
        setExpiryTime: (state) => {
            const date = new Date();

            date.setMinutes(date.getMinutes() + 30);

            state.expiryDate = date;
        },
        logout: (state) => {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
        cookies: (state) => {
            state.showCookies = false;
        }
    },
    plugins: [createPersistedState()]
})

const app = new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
});
