<template>
  <div class="news col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 col-12">
    <ul>
      <li>
        <b>15.04.2024.</b><br />
        Pievienota iespēja instruktoram veidot savu kursantu izlasi. Izlasi var rediģēt – pievienot / dzēst. Ja kursants pāriet apmācībā uz citu autoskolu vai pabeidz apmācību, automātiski tiek dzēsts no izlases saraksta.
      </li>
      <br />
      <li>
        <b>10.04.2024.</b><br />
        Programmā noņemta prasība par 5 min pauzi starp nodarbībām, t.sk. starp braukšanu un teoriju.
      </li>
      <br />
      <li>
        <b>04.04.2024.</b><br />
        Nodarbību tēmas programmā vairs nav jāuzrāda. Lai vienlaicīgi A kategorijas laukumā apmācītu vairākas personas, joprojām jāatzīmē Laukums (jāieķeksē).
      </li>
      <br />
      <li>
        <b>18.06.2021.</b><br />
        Nodarbību tēmas papildinātas ar “L-Laukums”. Reģistrējot A kategorijas
        “L-Laukums” nodarbību, vienlaicīgi iespējams reģistrēt vairākus
        kursantus.
      </li>
      <br />
      <li>
        <b>08.06.2021.</b><br />
        Ja personai aktīva apmācība vairākās mācību grupās, automātiski tiek
        atgriezta pēdējā aktīvā apmācība. Ja aktuāla ir cita grupa (mācību
        veids), tad attiecīgi jāizvēlas no “Citas grupas” saraksta.
      </li>
      <br />
      <li>
        <b>07.06.2021.</b><br />
        Pievienota braukšanas nodarbību vēstures sadaļa.
      </li>
      <br />
      <li>
        <b>27.05.2021.</b><br />
        Sadaļā “Dokumentācija” pievienota 25.05.2021. vebinārā izmantotā
        programmas sākotnējās versijas prezentācija.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "News",
};
</script>

<style scoped>
.news li {
  font-size: 12px;
}
</style>